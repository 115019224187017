<template>
  <div
    v-if="hiddenFlag || bannedFlag"
    class="absolute border flex flex-row justify-center items-center rounded-full -bottom-1"
    :class="[
      bannedFlag?'bg-badgeErrorPale border-badgeError':'bg-badgeInfoPale border-badgeInfo',
      horizontalOffset?'-left-'+horizontalOffset:'-left-2'
    ]"
  >
    <!-- Incognito icon -->
    <template v-if="hiddenFlag && !bannedFlag">
      <VTooltip>
        <base-icon
          class="m-0.5"
          :class="iconSize?'w-'+iconSize+' h-'+iconSize:'w-4 h-4'"
          name="incognito"
          color="fill-badgeNeutral"
          no-inline-block=true
        />
        <template #popper>
          {{ trans('LABEL:ProfileHidden') }}
        </template>
      </VTooltip>
    </template>
    <!-- Banned icon -->
    <template v-if="bannedFlag">
      <VTooltip>
        <base-icon
          class="m-0.5"
          :class="iconSize?'w-'+iconSize+' h-'+iconSize:'w-4 h-4'"
          name="ban"
          color="fill-badgeNeutral"
        />
        <template #popper>
          {{ trans('LABEL:AccountBlocked') }}
        </template>
      </VTooltip>
    </template>
  </div>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";
export default {
  name: "PictureIcons",
  components: {BaseIcon},
  props: {
    bannedFlag: Boolean,
    hiddenFlag: Boolean,
    iconSize: Number,
    horizontalOffset:Number
  },
}
</script>