<template>
  <div v-if="userData.rememberedUsers">
    <ListHeaderSection
      :is-favourite="true"
      :text="trans('LABEL:RememberedUsers')"
      :count="userData.rememberedUsers.length"
    >
      <base-icon :width="16" :height="16" name="starFilled"/>
    </ListHeaderSection>
    <ul v-if="userData.rememberedUsers.length > 0">
      <li :key="user.username" v-for="(user, index) in userData.rememberedUsers">
        <RememberedUserCard
          :remembered-user="user"
          :index="Number(index)"
          :remembered-users-length="Number(userData.rememberedUsers.length)"
        >
        </RememberedUserCard>
        <hr v-if="index !== userData.rememberedUsers.length - 1" class="border-grayedHR ml-16 my-2" />
      </li>
    </ul>
    <ul v-else>
      <li class="text-grayed text-s mt-3 lg:mt-4">
        {{ trans('MESSAGE:PlaceForRememberedUsers') }}
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import RememberedUserCard from "@/views/content/filters/components/RememberedUserCard";
import BaseIcon from "@/utils/icons/BaseIcon";
import ListHeaderSection from "@/views/content/messages/components/ListHeaderSection";

export default {
  name: "RememberedUsers",
  components: {ListHeaderSection, BaseIcon, RememberedUserCard},

  computed: {
    ...mapGetters('profile', ['rememberedUsers']),
    ...mapGetters('account', ['userData']),
  },

}
</script>

