<template>
  <div v-if="userData">
    <ListHeaderSection
      :text="trans('LABEL:SearchHistory')"
      no-counters
      :count="searchHistoryData && searchHistoryData.length"
    >
      <base-icon :width="16" :height="16" name="history">
      </base-icon>
    </ListHeaderSection>
    <div class="max-h-32 overflow-y-auto -mr-2 lg:-mr-4">
      <ul v-if="searchHistoryData && searchHistoryData.length > 0" class="flex flex-col" :class="{'gap-1.5':isMobile}">
        <li :key="history.id" v-for="(history, index) in searchHistoryData">
          <div class="flex flex-row w-full gap-2 items-center justify-between "
            :class="index===0?'mt-3 lg:mt-4':'mt-1 lg:mt-1.5'"
          >
            <VTooltip>
              <div class="flex w-full justify-start"
                :class="{
                  'text-grayed':currentLocation === history.location.id,
                  'text-redAccent':index===0 && highlightFirst
                }"
              >
                {{ history.location.descRegion }}
              </div>
              <template #popper>
                {{ history.createdAt }}
              </template>
            </VTooltip>

            <VTooltip>
              <Button
                v-show="userData.hasFullAccess && searchAllowed && currentLocation !== history.location.id"
                class="mr-2 lg:mr-4"
                @click="useLocation(history.location)"
              >
                <base-icon :width="isMobile?24:16" :height="isMobile?24:16" name="reset"/>
              </Button>
              <template #popper>
                {{ trans('TOOLTIP:UseThisLocation') }}
              </template>
            </VTooltip>
          </div>
        </li>
      </ul>
      <ul v-else>
        <li class="text-grayed text-s mt-3 lg:mt-4">
          {{ trans('MESSAGE:SearchLocationNone') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconLabel from "@/utils/IconLabel";
import BaseIcon from "@/utils/icons/BaseIcon";
import ListHeaderSection from "@/views/content/messages/components/ListHeaderSection";
import FavouriteFilterCard from "@/views/content/filters/components/FavouriteFilterCard";
import {mapActions, mapGetters} from "vuex";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

export default {
  name: "SearchLocation",
  components: {FavouriteFilterCard, ListHeaderSection, BaseIcon, IconLabel},
  data() {
    return {
      isHistoryShown: true,
    }
  },
  watch: {

  },
  props:{
    highlightFirst:Boolean,
    searchAllowed:Boolean,
    currentLocation:Number,
  },
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('lang', ['configData']),
	  ...mapGetters('searchHistories', ['searchHistoryData']),
    isMobile() {
      return checkIsMobileDevice();
    },
  },
  methods: {
		...mapActions('searchHistories', ['fetchSearchHistory']),
    toggleFavouriteFilters() {
      this.isHistoryShown = !this.isHistoryShown
    },
    useLocation(data) {
      this.$emit('location-selected', data);
    }
  },
	mounted() {
		this.fetchSearchHistory();
	}
}
</script>
