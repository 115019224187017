<template>
  <!-- USER CARD -->
    <div class=" cursor-pointer h-full">
      <div class="" @click.stop="$emit('clicked', userData.username)">
        <div class="opacity-100">
	        <div class="relative group">
            <Picture
              :classes="'transform duration-200 object-cover lg:w-204 lg:h-204 w-full rounded-3xl border border-grayed lg:group-hover:scale-101 lg:group-hover:border-redAccent '+getHeightOfScreenWidth(1)"
              loader-enabled
              :src="userData.featuredPictureUrl"
              :alt="`${userData.name} picture`"
            />
            <!-- Chat button -->
            <div v-if="userData.threadId"
              class="absolute z-40 w-16 lg:w-8 h-16 lg:h-8 transform duration-200 lg:hover:scale-110
                bottom-16 lg:bottom-12 -right-1.5 lg:-right-3"
            >
              <base-icon class="absolute bottom-0 right-0" :width="isMobile?66:33" :height="isMobile?66:33"
                color="fill-white" name="chatLines"
              >
              </base-icon>
              <t-icon-button
                variant="icon"
                @click="goToChat(userData)"
                class="absolute"
              >
                <VTooltip>
                  <base-icon :width="isMobile?60:30" :height="isMobile?60:30"
                    color="fill-badgeSuccess" name="chatLines"
                  >
                  </base-icon>
                  <template #popper>
                    {{ trans('TOOLTIP:GoToConversation') }}
                  </template>
                </VTooltip>
              </t-icon-button>
            </div>
            <!-- Remembered user star -->
            <div v-if="isRemembered && !userData.threadId"
              class="absolute flex justify-center items-center z-40 w-12 lg:w-8 h-12 lg:h-8
                bottom-12 -right-3 lg:-right-4"
            >
              <VTooltip>
                <base-icon class="relative" :width="isMobile?48:32" :height="isMobile?48:32"
                  color="fill-white" name="starFilled"
                >
                </base-icon>
                <base-icon class="absolute top-1.5 lg:top-1 left-1.5 lg:left-1" :width="isMobile?36:24" :height="isMobile?36:24"
                  color="fill-badgeInfo" name="starFilled"
                >
                </base-icon>
                <template #popper>
                  {{ trans('TOOLTIP:RememberedUser') }}
                </template>
              </VTooltip>
            </div>

            <!-- MOBILE Username, gender, age -->
            <div v-if="isMobile" class="absolute bottom-3 left-3 right-3 z-20">
              <div class="p-3 w-full bg-white border border-grayed rounded-xl bg-opacity-90">
                <h1 class="text-base leading-tight truncate">
                  {{ userData.name }}
                </h1>
                <span class="text-s leading-tight text-grayed mt-1">
                  {{ userData.gender.desc }}, {{ userData.age }}
                </span>
              </div>
            </div>

            <!-- DESKTOP Username, gender, age -->
            <div v-if="!isMobile" class="mt-3 w-full lg:w-204 lg:group-hover:text-redAccent lg:group-hover:opacity-70 group">
              <h1 class="text-base leading-tight truncate">
                {{ userData.name }}
              </h1>
              <span class="text-s leading-tight text-grayed lg:group-hover:text-redAccent mt-1">
                {{ userData.gender.desc }}, {{ userData.age }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- END USER CARD -->
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon.vue";
import router from "@/router";
import Button from "@/utils/crud/components/Button.vue";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import {getWidth, getHeightOfScreenWidth} from "@/common/helpers/utils";
import PictureLoader from "@/views/PictureLoader.vue";

export default {
  name: "UserCard",
	components: {PictureLoader, Button, BaseIcon},
  data(){
    return{
      isMobile: checkIsMobileDevice(),
      getHeightOfScreenWidth: getHeightOfScreenWidth,
      getWidth:getWidth,
    }
  },
  props: {
    userData: Object,
    index: Number,
    isRemembered: Boolean
  },
  methods: {
    goToChat(user) {
      router.push({name: 'chats', params: {id: user.threadId}})
    }
  }
}
</script>