<template>
  <div class="flex-col w-full shrink-0">
    <SearchHistory
      :highlight-first=highlightFirstLocation
      v-show="showSidebarNav(currentNav.history)"
      @location-selected="locationSelected"
      :search-allowed="searchAllowed"
      :current-location = currentLocation
    />
    <SidebarSectionSeparatorLine  v-show="showSidebarNav(currentNav.remembered)" class="block"/>
    <RememberedUsers
      v-show="showSidebarNav(currentNav.remembered)"
    />
  </div>
</template>

<script>
import Filters from "@/views/content/filters/Filters";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import RememberedUsers from "@/views/content/filters/RememberedUsers";
import SidebarSectionSeparatorLine from "@/views/content/components/SidebarSectionSeparatorLine"
import SearchHistory from "@/views/content/filters/SearchHistory.vue";


export default {
  name: "SearchSidebar",
  components: {
    SearchHistory,
    RememberedUsers,
    Filters,
    SidebarSectionSeparatorLine,
  },
  props: {
    currentNav: Object,
    highlightFirstLocation:Boolean,
    searchAllowed:Boolean,
    currentLocation:Number,
  },
  data() {
    return {

    }
  },
  computed: {

    isMobile() {
      return checkIsMobileDevice();
    }
  },
  methods: {

    showSidebarNav(val) {
      if(!this.isMobile) {
        return true;
      }
      return val;
    },

    locationSelected(data) {
      this.$emit('location-selected', data);
    }
  },
  mounted() {
  },

}
</script>