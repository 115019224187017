<template>
  <div class="flex flex-row leading-normal justify-between group cursor-pointer text-s"
    :class="{'mt-3 lg:mt-4':index===0}"
    @click="applyFavFilters(filter.id)"
  >
    <div class="flex flex-row flex-wrap w-full justify-between items-center ">
      <div :class="{
          'group-hover:text-redAccent': true,
          'font-semibold text-redAccent': activeFilter === filter.id,
          'font-base': activeFilter !== filter.id,
        }">
        {{ filter.name }}
      </div>
      <div class="flex flex-row space-x-2">

        <VTooltip>
          <Button
            variant="iconThreadAction"
            @clicked="editFilter(filter.id)"
          >
            <base-icon :width="14" :height="14" name="edit">
            </base-icon>
          </Button>
          <template #popper>
            {{ trans('TOOLTIP:UpdateName') }}
          </template>
        </VTooltip>

        <VTooltip>
          <Button
              variant="iconThreadAction"
              @clicked="removeFilterAction(filter.id)"
          >
            <base-icon :width="14" :height="14" name="trash">
            </base-icon>
          </Button>
          <template #popper>
            {{ trans('TOOLTIP:Remove') }}
          </template>
        </VTooltip>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Button from "@/utils/crud/components/Button";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
export default {
  name: "FavouriteFilterCard",
  components: {Button, BaseIcon},
  props: {
    filter: Object,
    index: [Number, String]
  },
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('users', ['activeFilter'])
  },
  methods: {
    ...mapActions('users', ['removeFavouriteFilter']),
    ...mapMutations('users', ['setFavFilters', 'setIsShowEditFilterNameModal', 'setFilterToEdit']),
    removeFilterAction(id) {
      this.removeFavouriteFilter(id)
    },
    applyFavFilters(id) {
      let filters = fromQueryToObject(this.userData.favouriteFilters.find(el => el.id === id).query);
      this.setFavFilters({filters: filters, id: id})
    },
    editFilter(id) {
      this.setFilterToEdit(id);
    }
  }
}
</script>
