	<template lang="html">
			<span>{{ hours }}:{{ minutes }}:{{ seconds }}</span>
	</template>

	<script>
	export default {
		name: "Countdown",
		props: {
			endDate: {
				type: Date,
			},
		},
		data () {
			return {
				days: null,
				hours: null,
				minutes: null,
				seconds: null,
				isEnded: null
			}
		},
		methods: {
			updateRemaining (distance) {
				this.hours = this.formatTime(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
				this.minutes = this.formatTime(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
				this.seconds = this.formatTime(Math.floor((distance % (1000 * 60)) / 1000))
			},

			tick () {
				const currentTime = new Date()
				const distance = Math.max(this.endDate - currentTime, 0)
				this.updateRemaining(distance)

				if (distance === 0) {
					clearInterval(this.timer)
					this.isEnded = true
					this.$emit('ended');
				}
			},

			formatTime(value) {
				return value < 10 ? '0' + value : value
			},
		},

		mounted () {
			this.tick()
			this.timer = setInterval(this.tick.bind(this), 1000)
		},

		destroy () {
			clearInterval(this.timer)
		}
	}
	</script>